<template>
  <section :class="b('')">
    <!-- <a :href="`/${currentSite.slug}`" :class="b('item')">{{
      currentSite.tab_title
    }}</a>
    /
    <a
      v-for="(link, index) in arPropLinks"
      :key="index"
      :href="`/${currentSite.slug}/${link.path}`"
      :class="b('item')"
    >
      {{ link.title }} {{ index === arPropLinks.length - 1 ? '' : '/' }}
    </a> -->
    <a href="#" :class="b('item')">Deliprofi / Сантехники</a>
  </section>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  name: 'ImplementerBreadcrumbs',
})
export default class ImplementerBreadcrumbs extends Vue {
  @Prop()
  readonly arPropLinks!: any[]

  @Prop({ default: 1 })
  readonly siteId!: number
}
</script>

<script>
export default {
  name: 'ImplementerBreadcrumbs',
  props: {
    arPropLinks: {
      type: Array,
      required: false,
    },

    siteId: {
      type: Number,
      default: 1,
    },
  },
}
</script>

<style lang="scss" scoped>
.ImplementerBreadcrumbs {
  a {
    color: $white;
  }
}
</style>
